/*
 * -------------------------------------------------------------------------------
 *
 * Copyright 2022 Valory AG
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * -------------------------------------------------------------------------------
 */

import { keyframes } from 'styled-components';

export const boxBottomRight = keyframes`
  0% {
    width: 0;
    height: 0;
    padding-top: 0;
    visibility: visible;
  }
  25% {
    width: 100%;
    height: 0;
    padding-top: 0;
    visibility: visible;
  }
  50% {
    height: 100%;
    width: 100%;
    visibility: visible;
  }
  75% {
    visibility: visible;
    
  }
  100% {
    visibility: visible;
  }
`;

export const boxTopLeft = keyframes`
  0% {
    width: 0;
    height: 0;
    padding-bottom: 0;
    visibility: hidden;
  }
  25% {
    width: 0;
    height: 0;
    padding-bottom: 0;
    visibility: hidden;
  }
  50% {
    width: 0;
    height: 0;
    padding-bottom: 0;
    visibility: hidden;
  }
  75% {
    width: 100%;
    height: 0;
    padding-bottom: 0;
    visibility: visible;
  }
  100% {
    width: 100%;
    height: 100%;
    opacity: 1;
    visibility: visible;
  }
`;

export const lineLeftToRight = keyframes`
  0% {
    width: 0;
    padding-bottom: 0;
    visibility: hidden;
  }
  100% {
    width: 100%;
    visibility: visible;
  }
`;

export const topToBottom = keyframes`
  0% {
    height: 0;
    visibility: hidden;
  }
  100% {
    height: 100%;
    visibility: visible;
  }
`;

export const loadingAnimation = keyframes`
  0% {
    left: 0;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 0;
  }
`;
