/*
 * -------------------------------------------------------------------------------
 *
 * Copyright 2022 Valory AG
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * -------------------------------------------------------------------------------
 */

/* eslint-disable jsx-a11y/img-redundant-alt */
import { Spin } from 'antd/lib';
import { LoadingOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import NumbersAnimate from 'common-util/NumbersAnimate';
import { Box } from 'common-util/BorderAnimate';
import { Previous } from 'common-util/components';
import {
  TableHeader,
  TableBodyContainer,
  TableBody,
  WritesRow,
  BorderLine,
  NoData,
} from './styles';

const ListDataSources = ({
  observations,
  triggerObservationAnimation,
  triggerEstimateAnimation,
  dataSources,
  walletUrls,
}) => {
  const hasObservations = Object.entries(observations).length !== 0;

  const agentsCount = hasObservations ? (
    <>{`(${Object.entries(dataSources).length})`}</>
  ) : null;

  return (
    <>
      <TableHeader>
        <div>Data Sources</div>
        <div>
          Agents&nbsp;
          {agentsCount}
        </div>
        <div>Observed Prices</div>
      </TableHeader>

      {hasObservations ? (
        <TableBodyContainer hasObservations={hasObservations}>
          <TableBody className={triggerEstimateAnimation ? 'active' : ''}>
            {Object.entries(dataSources).map(([key, name], index) => {
              const active = triggerObservationAnimation
                ? 'border-active'
                : 'border-loading';
              const price = observations[key] ? (
                <NumbersAnimate value={observations[key]} />
              ) : (
                '--'
              );

              const openNetwork = () => window.open(`https://${name.toLowerCase()}.com`);

              const openAgent = () => window.open(walletUrls[key]);

              // if we have more than 4 agents, use agent-1 image
              const agentCount = index + 1 > 4 ? 1 : index + 1;

              return (
                <WritesRow key={`writes-row-${key}`}>
                  <div
                    className="writes-box data-source"
                    onClick={openNetwork}
                    onKeyPress={openNetwork}
                    role="button"
                    tabIndex={0}
                  >
                    <img
                      src={`/images/${name.toLowerCase()}.png`}
                      alt={`${name}-image`}
                    />
                  </div>
                  <BorderLine className={`source-border ${active}`} />
                  <Box isUpdated={triggerObservationAnimation}>
                    <div
                      className="writes-box agent"
                      onClick={openAgent}
                      onKeyPress={openAgent}
                      role="button"
                      tabIndex={0}
                    >
                      <img
                        src={`/images/agent-${agentCount}${
                          triggerObservationAnimation ? '-smile' : ''
                        }.png`}
                        alt={`${name}-bot-image`}
                      />
                    </div>
                  </Box>
                  <BorderLine className={`agent-border ${active}`} />
                  <div
                    className={`observed-price ${
                      triggerEstimateAnimation ? '' : 'empty'
                    }`}
                  >
                    <span className="price-text">
                      {triggerEstimateAnimation ? price : '--'}
                    </span>
                    <Previous
                      isAnimationOngoing={triggerEstimateAnimation}
                      value={observations[key] || null}
                    />
                  </div>
                </WritesRow>
              );
            })}
          </TableBody>
          <div
            className={`mini-line ${triggerEstimateAnimation ? 'active' : ''}`}
          />
        </TableBodyContainer>
      ) : (
        <NoData>
          <Spin indicator={<LoadingOutlined spin />} />
        </NoData>
      )}
    </>
  );
};

ListDataSources.propTypes = {
  triggerObservationAnimation: PropTypes.bool,
  triggerEstimateAnimation: PropTypes.bool,
  observations: PropTypes.shape({}),
  dataSources: PropTypes.shape({}),
  walletUrls: PropTypes.shape({}),
};

ListDataSources.defaultProps = {
  triggerObservationAnimation: false,
  triggerEstimateAnimation: false,
  observations: {},
  dataSources: {},
  walletUrls: {},
};

export default ListDataSources;
