/*
 * -------------------------------------------------------------------------------
 *
 * Copyright 2022 Valory AG
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * -------------------------------------------------------------------------------
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from './styles';

// eslint-disable-next-line import/prefer-default-export
export const Box = ({ isUpdated, children }) => (
  <Wrapper>
    <div className={`box-container ${isUpdated ? 'active' : ''}`}>
      {children}
    </div>
  </Wrapper>
);

Box.propTypes = {
  isUpdated: PropTypes.bool,
  children: PropTypes.element,
};

Box.defaultProps = {
  isUpdated: false,
  children: null,
};
