/*
 * -------------------------------------------------------------------------------
 *
 * Copyright 2022 Valory AG
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * -------------------------------------------------------------------------------
 */

/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import get from 'lodash/get';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import { useTransition, animated } from 'react-spring';
import { dollarFormat, statusColor } from 'common-util/functions';
import { useCheckMobileScreen } from 'common-util/hooks';
import {
  WritesOnChainStatus,
  WritesToChainContainer,
  Ellipsis,
  SignatureAndTransaction,
  StatusRow,
  WritesSubTitle,
} from './styles';

TimeAgo.addDefaultLocale(en);

const getKey = (value) => {
  const periodCount = get(value, 'periodCount');
  const timeStamp = get(value, 'time_stamp');
  return `block_${periodCount || timeStamp}`;
};

const WritesToChain = ({ list }) => {
  const isMobile = useCheckMobileScreen();
  const height = isMobile ? 156 : 132;
  const values = Object.values(list || []);

  const transitions = useTransition(
    values.map((data, i) => ({ ...data, y: i * height })),
    {
      keys: (item) => getKey(item),
      from: { position: 'absolute', opacity: 0, left: -50 },
      enter: ({ y }) => ({ y, opacity: 1, left: 0 }),
      update: ({ y }) => ({ y }),
    },
  );

  return (
    <WritesToChainContainer
      style={{ height: `${values.length * (isMobile ? 160 : 134)}px` }}
    >
      <WritesSubTitle>Writes to chain</WritesSubTitle>
      {(values || []).length === 0 ? (
        <div>No writes to display yet</div>
      ) : (
        <>
          {transitions((_props, value) => {
            /* 1st key of any data source in the object */
            const dataSourceKey = Object.keys(
              get(value, 'signatures') || [],
            )[0];

            const currentEstimate = get(value, 'estimate');
            const { status, color } = statusColor(get(value, 'status'));
            const signature = get(value, `signatures.${dataSourceKey}`);
            const transaction = get(value, 'tx_hash');
            const timeStamp = get(value, 'time_stamp');
            const url = get(value, 'transaction_url', '#');
            const getTimeAgo = () => {
              if (!timeStamp) return '--';
              return new TimeAgo('en-US').format(Date.parse(timeStamp));
            };
            const { opacity, y, ...rest } = _props;

            return (
              <animated.div
                style={{
                  opacity: opacity || 1,
                  transform: y.interpolate((e) => `translate3d(0,${e}px,0)`),
                  width: '100%',
                  ...rest,
                }}
              >
                <WritesOnChainStatus color={color}>
                  <p className="price">{dollarFormat(currentEstimate)}</p>

                  <StatusRow color={color}>
                    <div className="status">{status}</div>
                    <div className="time-ago">{getTimeAgo()}</div>
                  </StatusRow>

                  <SignatureAndTransaction>
                    <div className="signature">
                      <span>Signature:&nbsp;</span>
                      <Ellipsis>{signature || '--'}</Ellipsis>
                    </div>
                    <div className="dot">&#8231;</div>
                    <div className="transaction">
                      <span>Transaction:&nbsp;</span>
                      {transaction ? (
                        <a
                          href={url}
                          target="_blank"
                          rel="noopener noreferrer"
                          aria-label="ropsten-transaction"
                        >
                          <Ellipsis>{transaction}</Ellipsis>
                          <span className="external-link">
                            <Image
                              src="/images/external-link.svg"
                              alt=""
                              width={18}
                              height={16}
                            />
                          </span>
                        </a>
                      ) : (
                        <Ellipsis>--</Ellipsis>
                      )}
                    </div>
                  </SignatureAndTransaction>
                </WritesOnChainStatus>
              </animated.div>
            );
          })}
        </>
      )}
    </WritesToChainContainer>
  );
};

WritesToChain.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})),
};

WritesToChain.defaultProps = {
  list: [],
};

export default WritesToChain;
