/*
 * -------------------------------------------------------------------------------
 *
 * Copyright 2022 Valory AG
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * -------------------------------------------------------------------------------
 */

import styled from 'styled-components';
import { COLOR, MEDIA_QUERY } from 'common-util/theme';

// ------ common -------
export const Ellipsis = styled.span`
  max-width: 80px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${MEDIA_QUERY.laptop} {
    max-width: 60px;
  }

  ${MEDIA_QUERY.tablet} {
    max-width: 200px;
  }
`;

export const WritesSubTitle = styled.div`
  font-size: 18px;
  margin-bottom: 1rem;
  ${MEDIA_QUERY.tablet} {
    margin-bottom: 0.5rem;
  }
`;

// ------ writes to chain (right section) -------
export const WritesToChainContainer = styled.div`
  position: relative;
  min-height: 96px;
`;

export const WritesOnChainStatus = styled.div`
  padding: 0.5rem;
  margin-bottom: 1.5rem;
  border: 1px solid ${COLOR.BLACK};
  border-radius: 4px;
  overflow: hidden;
  .price {
    font-size: 28px;
    font-weight: bold;
    margin: 0;
    line-height: normal;
  }
`;

export const StatusRow = styled.div`
  display: flex;
  margin: 4px 0;
  .status {
    display: flex;
    align-items: center;
    color: ${COLOR.GREY_1};
    padding: 0 8px;
    border-radius: 6px;
    background: ${({ color }) => `${color}`};
    margin-right: 8px;
    font-size: 14px;
  }
  .time-ago {
    white-space: nowrap;
  }
`;

export const SignatureAndTransaction = styled.div`
  display: flex;
  .signature,
  .transaction {
    display: flex;
    align-items: center;
    a {
      display: flex;
      align-items: center;
      line-height: normal;
      margin-bottom: -2px;
      color: inherit;
      ${Ellipsis} {
        text-decoration: underline;
      }
    }
    .external-link {
      width: 14px;
    }
  }
  .transaction {
    margin-left: 8px;
  }

  ${MEDIA_QUERY.laptop} {
  }

  ${MEDIA_QUERY.tablet} {
    flex-direction: column;
    .dot {
      display: none;
    }
    .transaction {
      margin-left: 0;
    }
  }
`;
