/*
 * -------------------------------------------------------------------------------
 *
 * Copyright 2022 Valory AG
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * -------------------------------------------------------------------------------
 */

/* eslint-disable import/prefer-default-export */
import { COLOR } from 'common-util/theme';
import styled from 'styled-components';
import { boxBottomRight, boxTopLeft } from '../keyframes';

export const Wrapper = styled.div`
  display: flex;
  margin: 0 auto;
  .box-container {
    position: relative;
    overflow: hidden;
    transition: color 0.75s ease-in-out;
  }
  .box-container.active:before,
  .box-container.active:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    visibility: hidden;
    overflow: hidden;
  }
  .box-container.active:after {
    bottom: 0px;
    left: 0px;
    border-bottom: 1px solid ${COLOR.PRIMARY};
    border-right: 1px solid ${COLOR.PRIMARY};
  }
  .box-container.active:before {
    top: 0px;
    right: 0px;
    border-top: 1px solid ${COLOR.PRIMARY};
    border-left: 1px solid ${COLOR.PRIMARY};
  }
  .box-container.active:before {
    animation: ${boxTopLeft} 0.5s ease-in-out 300ms forwards;
  }
  .box-container.active:after {
    animation: ${boxBottomRight} 0.5s ease-in-out 300ms forwards;
  }
`;
