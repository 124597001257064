/*
 * -------------------------------------------------------------------------------
 *
 * Copyright 2022 Valory AG
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * -------------------------------------------------------------------------------
 */

import styled from 'styled-components';
import { MEDIA_QUERY } from 'common-util/theme';

export const SubHeader = styled.div`
  .ant-typography {
    margin-bottom: 0.5rem;
  }
  .sub-header-price {
    font-weight: bold;
  }
  ${MEDIA_QUERY.tablet} {
    .sub-header-title {
      font-size: 46px;
    }
  }
`;

export const OnChainWritesTable = styled.div`
  min-height: calc(100vh - 300px);
  .sub-header {
    font-size: 20px;
  }
  ${MEDIA_QUERY.tablet} {
    min-height: calc(100vh - 380px);
  }
`;

export const WritesContainer = styled.div`
  display: flex;
  margin: 2rem 0 0 0;
  .writes-table {
    max-width: 680px;
    min-width: 652px;
    width: 100%;
    min-height: 500px;
  }
  .writes-aggregate-price,
  .writes-on-chain {
    margin-top: 215px;
    max-width: 340px;
  }
  .writes-aggregate-price {
    padding: 0 3rem 0 4rem;
    width: 100%;
  }
  .writes-on-chain {
    width: 100%;
    max-width: 420px;
  }

  ${MEDIA_QUERY.desktop} {
    .writes-table {
      min-width: 600px;
    }
    .writes-aggregate-price,
    .writes-on-chain {
      max-width: 320px;
    }
  }

  ${MEDIA_QUERY.laptop} {
    .writes-table {
      min-width: 480px;
    }
    .writes-aggregate-price {
      padding: 0 2rem 0 3rem;
      max-width: 264px;
    }
    .writes-on-chain {
      max-width: 320px;
    }
  }

  ${MEDIA_QUERY.tabletL} {
    flex-direction: column;
    .writes-table {
      min-height: auto;
      min-width: auto;
    }
    .writes-aggregate-price,
    .writes-on-chain {
      max-width: 380px;
    }
    .writes-aggregate-price {
      margin: 2rem 0 2rem 0;
      padding: 0;
    }
    .writes-on-chain {
      margin-top: 0;
    }
  }
`;

export const WritesSubTitle = styled.div`
  font-size: 18px;
  margin-bottom: 1rem;
  ${MEDIA_QUERY.tablet} {
    margin-bottom: 0.5rem;
  }
`;

// ------ aggregate price (middle section) -------
export const AggregatePriceContainer = styled.div`
  p {
    &:nth-child(2) {
      margin-bottom: 0.5rem;
      line-height: 36px;
      font-size: 38px;
      font-weight: bold;
    }
  }

  ${MEDIA_QUERY.laptop} {
    p {
      &:nth-child(2) {
        line-height: 28px;
        font-size: 30px;
      }
    }
  }
`;
