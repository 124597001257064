/*
 * -------------------------------------------------------------------------------
 *
 * Copyright 2022 Valory AG
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * -------------------------------------------------------------------------------
 */

import React from 'react';
import AnimatedNumber from 'react-animated-number';
import PropTypes from 'prop-types';
import { dollarFormat } from 'common-util/functions';

const NumbersAnimate = ({ value, formatValue, ...rest }) => (
  <AnimatedNumber
    duration={675}
    value={value}
    formatValue={formatValue || ((n) => dollarFormat(n))}
    {...rest}
  />
);

NumbersAnimate.propTypes = {
  value: PropTypes.number,
  formatValue: PropTypes.func,
};

NumbersAnimate.defaultProps = {
  value: 0,
  formatValue: null,
};

export default NumbersAnimate;
