/*
 * -------------------------------------------------------------------------------
 *
 * Copyright 2022 Valory AG
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * -------------------------------------------------------------------------------
 */

import React, { Component } from 'react';
import getConfig from 'next/config';
import { Typography } from 'antd/lib';
import io from 'socket.io-client';
import get from 'lodash/get';
import NumbersAnimate from 'common-util/NumbersAnimate';
import { Previous } from 'common-util/components';
import ListDataSource from './OnChainWrites/ListDataSources';
import WritesToChain from './OnChainWrites/WritesToChain';
import {
  OnChainWritesTable,
  WritesContainer,
  AggregatePriceContainer,
  WritesSubTitle,
  SubHeader,
} from './styles';

const { Title } = Typography;

const { publicRuntimeConfig } = getConfig();
const { ORACLE_BACKEND_URL } = publicRuntimeConfig;

class PriceReporter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      configuration: {}, // stores config data
      triggerObservationAnimation: false,
      triggerEstimateAnimation: false,
      rawData: {}, // actual response from server with period-count
      data: [], // converted rawData to array (statuses column)
      value: {}, // last block sent from server
      headerEstimate: null,
      estimate: null,
    };
  }

  componentDidMount = () => {
    this.getConfiguration();
    this.getInitialData();

    // web-socket for live-data
    this.socket = io.connect(ORACLE_BACKEND_URL);
    this.socket.on('new_data', (message) => {
      // updating the state after the animation is performed
      setTimeout(() => {
        this.updateWithData(message);
      }, 1600);
    });
  };

  componentWillUnmount() {
    this.socket.close();
  }

  getBlockHelpers = (objects) => {
    // list of period-count (keys) in descending order, eg. [5, 4, 3, 2, 1]
    const sortedKeyList = Object.keys(objects)
      .map((e) => Number(e))
      .sort((a, b) => b - a);

    // stores the highest period count, eg. 5
    const max = sortedKeyList[0];

    const sortedData = [];
    sortedKeyList.forEach((e) => sortedData.push({ ...objects[e], periodCount: e }));

    return { max, sortedData };
  };

  getConfiguration = async () => {
    try {
      const response = await fetch(`${ORACLE_BACKEND_URL}/configuration`);
      const config = await response.json();
      this.setState({ configuration: config });
    } catch (error) {
      console.error(error);
    }
  };

  // API call to load initial data
  getInitialData = async () => {
    try {
      const response = await fetch(`${ORACLE_BACKEND_URL}/data`);
      const rawBlocks = await response.json();
      const { max, sortedData } = this.getBlockHelpers(rawBlocks);
      const value = rawBlocks[`${max}`];
      const estimate = get(value, 'estimate');
      const dataSources = get(value, 'data_sources') || {};

      this.setState({
        rawData: rawBlocks,
        data: [...sortedData],
        value,
        dataSources,
        headerEstimate: estimate,
      });
    } catch (error) {
      console.error(error);
    }
  };

  updateWithData = (currentData) => {
    const { rawData } = this.state;
    const updatedRawData = { ...rawData, ...currentData };
    const { sortedData } = this.getBlockHelpers(updatedRawData);

    // currentData will have 2 keys, one with a new transaction block
    // and the other with a transaction block already present
    const keys = Object.keys(currentData);

    // since the object doesn't guarantee the order, we will check which key
    // is the highest and consider that to be the new transaction.
    const value = Number(keys[0]) > Number(keys[1])
      ? currentData[keys[0]]
      : currentData[keys[1]];
    const estimate = get(value, 'estimate');
    const dataSources = get(value, 'data_sources') || {};

    // triggers animation for observation
    setTimeout(() => {
      this.setState({
        triggerObservationAnimation: true,
        estimate: null,
      });
    }, 0);

    // once observation animation is completed
    // update the data required for observations
    setTimeout(() => {
      this.setState({
        rawData: updatedRawData,
        value,
        dataSources,
      });
    }, 100);

    // after observation data is updated, trigger estimation animation
    setTimeout(() => {
      this.setState({ triggerEstimateAnimation: true });
    }, 2000);

    // once estimation animation is completed
    // update the data required for estimation
    setTimeout(() => {
      this.setState({ estimate, headerEstimate: estimate });
    }, 4150);

    // once estimate is rendered
    // update `writes-to-chain`
    setTimeout(() => {
      this.setState({ data: sortedData });
    }, 5000);

    // reset everything at the end
    setTimeout(() => {
      this.setState({
        triggerObservationAnimation: false,
        triggerEstimateAnimation: false,
      });
    }, 6000);
  };

  render() {
    const {
      configuration,
      data,
      value,
      headerEstimate,
      estimate,
      dataSources,
      triggerEstimateAnimation,
      triggerObservationAnimation,
    } = this.state;

    return (
      <>
        <SubHeader>
          <Title>BTC/USD Price</Title>

          <div>
            Latest&nbsp;
            <span className="sub-header-price">
              {headerEstimate ? (
                <NumbersAnimate value={headerEstimate} />
              ) : (
                '--'
              )}
            </span>
            &nbsp;&#8231;&nbsp;Updates every ~
            {`${get(configuration, 'observation_interval') || 0}s`}
          </div>
        </SubHeader>

        <OnChainWritesTable>
          <WritesContainer>
            <div className="writes-table">
              <ListDataSource
                networkUrl={get(configuration, 'network') || {}}
                observations={get(value, 'observations') || {}}
                walletUrls={get(value, 'wallet_urls') || {}}
                dataSources={dataSources}
                triggerObservationAnimation={triggerObservationAnimation}
                triggerEstimateAnimation={triggerEstimateAnimation}
              />
            </div>

            <div className="writes-aggregate-price">
              <AggregatePriceContainer>
                <WritesSubTitle>Aggregate Price</WritesSubTitle>
                <p>
                  {triggerEstimateAnimation ? (
                    <>{estimate ? <NumbersAnimate value={estimate} /> : '--'}</>
                  ) : (
                    '--'
                  )}
                </p>
                <Previous
                  isAnimationOngoing={triggerEstimateAnimation}
                  value={get(value, 'estimate') || null}
                />
              </AggregatePriceContainer>
            </div>

            <div className="writes-on-chain">
              <WritesToChain list={data} />
            </div>
          </WritesContainer>
        </OnChainWritesTable>
      </>
    );
  }
}

export default PriceReporter;
