/*
 * -------------------------------------------------------------------------------
 *
 * Copyright 2022 Valory AG
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * -------------------------------------------------------------------------------
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { dollarFormat } from './functions';

export const PreviousSpan = styled.span`
  font-size: 13px;
  &.center {
    text-align: center;
  }
`;

export const Previous = ({ isAnimationOngoing, value }) => {
  if (isAnimationOngoing) {
    return <PreviousSpan className="center">--</PreviousSpan>;
  }

  if (!value && value !== 0) {
    return <PreviousSpan>Last: N/A</PreviousSpan>;
  }

  return <PreviousSpan>{`Last: ${dollarFormat(value)}`}</PreviousSpan>;
};

Previous.propTypes = {
  isAnimationOngoing: PropTypes.bool.isRequired,
  value: PropTypes.number,
};

Previous.defaultProps = {
  value: null,
};

export const AB = null;
