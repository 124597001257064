/*
 * -------------------------------------------------------------------------------
 *
 * Copyright 2022 Valory AG
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * -------------------------------------------------------------------------------
 */

import styled from 'styled-components';
import { COLOR, MEDIA_QUERY } from 'common-util/theme';
import {
  lineLeftToRight,
  loadingAnimation,
  topToBottom,
} from 'common-util/keyframes';

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  padding: 1rem 0 1rem 0;
  > div {
    &:nth-child(2) {
      margin-left: -1.5rem;
    }
  }
  ${MEDIA_QUERY.tablet} {
    font-size: 14px;
  }
`;

export const TableBodyContainer = styled.div`
  position: relative;
  .mini-line {
    position: absolute;
    right: -50px;
    height: 3px;
    background: ${({ hasObservations }) => (hasObservations ? COLOR.GREY_2 : 'transparent')};
    width: 50px;
    top: 145px;
    &:after {
      content: "";
      position: absolute;
      width: 0;
      top: 2px;
      height: 1px;
      transform: translate(0, -1px);
      background: ${COLOR.PRIMARY};
      border-radius: 4px;
    }
    &.active:after {
      animation: ${lineLeftToRight} 0.25s ease-in-out 1.5s forwards;
    }
  }

  ${MEDIA_QUERY.laptop} {
    .mini-line {
      right: -30px;
      width: 30px;
    }
  }

  ${MEDIA_QUERY.tablet} {
    .mini-line {
      display: none;
      &.active {
        display: none;
      }
    }
  }
`;

export const TableBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  border-right: 3px solid ${COLOR.GREY_2};
  &.active:before {
    content: "";
    position: absolute;
    height: 100%;
    visibility: hidden;
    overflow: hidden;
    top: 0px;
    left: calc(100% + 1px);
    border-right: 1px solid ${COLOR.PRIMARY};
  }
  &.active:before {
    animation: ${topToBottom} 0.75s ease-in-out 0.75s forwards;
  }
  ${MEDIA_QUERY.tablet} {
    border-right: none;
    &:before,
    &:after {
      display: none;
    }
  }
`;

export const WritesRow = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1rem;
  flex: 0 1;
  .writes-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border: 1px solid ${COLOR.BORDER};
    img {
      width: 40px;
      height: 40px;
      &[alt="binance-image"],
      &[alt="kraken-image"] {
        width: auto;
      }
    }
    &.agent {
      img {
        width: auto !important;
      }
    }
    &:hover {
      cursor: pointer;
      border: 1px solid ${COLOR.PRIMARY};
      transition: border 0.15s ease-in;
    }
  }
  .observed-price {
    display: flex;
    flex-direction: column;
    min-width: 120px;
    .price-text {
      text-align: center;
      font-size: 16px;
    }
    &.empty {
      padding-left: 0px;
    }
  }

  ${MEDIA_QUERY.laptop} {
    .writes-box {
      width: 64px;
      height: 64px;
    }
  }

  ${MEDIA_QUERY.tablet} {
    .writes-box {
      width: 50px;
      height: 50px;
      img {
        width: 24px;
        height: 24px;
      }
    }
    .observed-price {
      min-width: 78px;
      .price-text {
        font-size: 16px;
      }
    }
  }
`;

export const BorderLine = styled.div`
  flex: 1;
  min-width: 150px;
  height: 3px;
  background: ${COLOR.GREY_2};
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  &:after {
    content: "";
    position: absolute;
    width: 0;
    top: 2px;
    height: 1px;
    transform: translate(0, -1px);
    background: ${COLOR.PRIMARY};
    border-radius: 4px;
  }
  &.source-border.border-active:after {
    animation: ${lineLeftToRight} 0.5s ease-in-out 0ms forwards;
  }
  &.agent-border.border-active:after {
    animation: ${lineLeftToRight} 0.5s ease-in-out 600ms forwards;
  }
  &.border-loading:after {
    top: 1px;
    width: 60px;
    position: absolute;
    transform: translate(-30px, 0);
    background-color: ${COLOR.PRIMARY};
    border-radius: 2px;
    animation: ${loadingAnimation} 1.4s infinite ease;
  }
  
  ${MEDIA_QUERY.desktop} {
    min-width: 120px;
  }

  ${MEDIA_QUERY.laptop} {
    min-width: 90px;
  }

  ${MEDIA_QUERY.tablet} {
    height: 3px;
    min-width: 58px;
  }
`;

export const NoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 360px;
  font-size: 24px;
  .anticon-loading {
    font-size: 46px;
  }
`;
