/*
 * -------------------------------------------------------------------------------
 *
 * Copyright 2022 Valory AG
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * -------------------------------------------------------------------------------
 */

import { COLOR } from './theme';

export const dollarFormat = (number) => {
  // Need to show `Missing` if backend sends NaN.
  if (Number.isNaN(number)) return 'Missing';

  return new Intl.NumberFormat('ja-US', {
    style: 'currency',
    currency: 'USD',
  }).format(number);
};

export const statusColor = (status) => {
  if (status === 'failed') return { status: 'FAILED', color: COLOR.RED };
  if (status === 'success') return { status: 'SUCCESS', color: COLOR.GREEN };
  return { status: 'PENDING', color: COLOR.ORANGE };
};
